export const MOUNT_BEFORE = 'mount.before';
export const MOUNT_AFTER = 'mount.after';
export const UPDATE = 'update';
export const PLAY = 'play';
export const PAUSE = 'pause';
export const BUILD_BEFORE = 'build.before';
export const BUILD_AFTER = 'build.after';
export const RUN_BEFORE = 'run.before';
export const RUN = 'run';
export const RUN_AFTER = 'run.after';
export const RUN_OFFSET = 'run.offset';
export const RUN_START = 'run.start';
export const RUN_END = 'run.end';
export const MOVE = 'move';
export const MOVE_AFTER = 'move.after';
export const RESIZE = 'resize';
export const SWIPE_START = 'swipe.start';
export const SWIPE_MOVE = 'swipe.move';
export const SWIPE_END = 'swipe.end';
export const TRANSLATE_JUMP = 'translate.jump';
